<template>
  <div class="page" v-if="state.page.id">
    <BreadcrumbsNavigation :page="state.page" />
    <main ref="page">
      <div
          class="video-container"
          :class="[state.page.slug]"
          ref="container">
        <div class="header-content">
          <h1>{{ state.page.title.rendered }}</h1>
          <ul v-if="childPages.length !== 0" class="child-pages">
            <li
                v-for="page in childPages"
                :key="page.id"
                @click="goTo(page)">
              {{ page.title }}
            </li>
          </ul>
        </div>
      </div>
      <span
          v-if="state.page.content.rendered !== ''"
          class="scroll-icon">
        <span class="scroll-icon__dot" />
        <span class="scroll-desc">scrollen</span>
      </span>
    </main>
    <div
        v-if="state.page.content.rendered !== ''"
        class="wp-content"
        v-html="state.page.content.rendered"
        @click="catchEvent" />
  </div>
</template>

<script>
import { onMounted, reactive, computed, ref, watch, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import useEmitter from '@/composables/useEmitter'
import axios from 'axios'
import BreadcrumbsNavigation from '@/components/BreadcrumbsNavigation'

export default {
  components: {
    BreadcrumbsNavigation
  },

  setup() {
    const store  = useStore()
    const route  = useRoute()
    const router = useRouter()
    const emitter   = useEmitter()
    const page      = ref(null)
    const container = ref(null)
    const state  = reactive({
      page: {}
    })

    const childPages = computed(() => store.getters.pages.filter((page) => page.parent === parseInt(route.params.id)))

    const setContainerSize = () => {
      if (!container.value) return
      let maxHeight = page.value.clientHeight

      let height = maxHeight
      let width  = height * 1.6

      if (width < page.value.clientWidth) {
        height = page.value.clientWidth / 1.6
        width  = height * 1.6
      }

      container.value.style.width  = `${width}px`
      container.value.style.height = `${height}px`
    }

    const loadPage = async (id) => {
      await axios.get(`${process.env.VUE_APP_BACKEND}/wp-json/wp/v2/pages/${id}`)
        .then(res => {
          const page = res.data
          if (page.type === 'page' && page.status === 'publish') state.page = res.data
          else router.push({ name: '404' })
        })
        .catch(() => router.push({ name: '404' }))
    }

    const goTo = (page, back) => {
      emitter.emit('play-btn-sound')
      router.push({
        name: page.parent === 0 ? 'Topic' : 'Page',
        params: { id: back ? page.parent : page.id }
      })
    }

    const catchEvent = (e) => {
      let a = e.target
      if (a.localName !== 'a') {
        if (a.parentNode.localName === 'a') a = a.parentNode
        else return
      }
      e.preventDefault()
      const page = store.getters.pages.find((page) => page.link === a.href)
      goTo(page)
    }

    onMounted(() => loadPage(route.params.id))

    watch(state, () => {
      nextTick(() => setContainerSize())
    })

    window.onresize = () => setContainerSize()

    return {
      page,
      container,
      state,
      route,
      childPages,
      goTo,
      catchEvent
    }
  }
}
</script>


<style lang="scss" scoped>
.page {
  position: absolute;
  top: 6%;
  left: 15%;
  width: 85%;
  height: 94%;
  overflow: auto;

  main {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    h1 {
      font-size: 4rem;
      line-height: 1.1em;
      font-weight: 400;
      margin: 2rem 2rem 0 2rem;
      color: #fff;
    }

    .video-container {
      background: url('../assets/img/bg.png') center center no-repeat;
      background-size: cover !important;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      height: 100%;
      transform: translate(-50%, -50%);
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &.umwelt { background: url('/static/img/nemo_1.jpg') center center no-repeat; }
      &.mobilitaet { background: url('/static/img/nemo_2.jpg') center center no-repeat; }
      &.technik { background: url('/static/img/nemo_3.jpg') center center no-repeat; }
      &.automatisierung { background: url('/static/img/nemo_4.jpg') center center no-repeat; }
      &.gesellschaftliche-auswirkung { background: url('/static/img/nemo_5.jpg') center center no-repeat; }
    }

    .child-pages {
      padding: 0;
      margin: 2rem 2rem 0 2rem;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      li {
        margin: 0.35rem;
        background-color: #4dbdbf;
        color: #000;
        padding: 0.25rem 0.85rem;
        border-radius: 1.5rem;
        cursor: pointer;
        transition: all 250ms ease-in-out;
        font-weight: 600;

        &:hover {
          background-color: #fff;
          color: #222;
        }
      }
    }
  }

  .header-content {
    position: relative;
    z-index: 10;
    text-align: center;
    margin: 2rem;
    padding-bottom: 2rem;
    border-radius: 0.5rem;
    max-width: 80%;
    background: rgba($color: #1c1c24, $alpha: 0.25) repeating-linear-gradient( -45deg, rgba($color: #1c1c24, $alpha: 0.35), rgba($color: #1c1c24, $alpha: 0.35) 2px, transparent 2px, transparent 6px );
  }

  .wp-content {
    padding: 4rem 2rem;
    position: relative;
    z-index: 10;
  }
}
</style>
